import type React from 'react';
// import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    return (
        <header>
            <nav>
                <ul>
                    {/* <li>mochi534's portfolio</li>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/projects">Projects</Link></li> */}
                </ul>
            </nav>
        </header>


    );
};

export default Header;
