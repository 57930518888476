import React from 'react';
import ProjectCard from '../components/ProjectCard';

interface Project {
    title: string;
    description: string;
    link: string;
}

const projects: Project[] = [
    {
        title: 'Project 1',
        description: 'Description for project 1',
        link: 'https://example.com/project1'
    },
    {
        title: 'Project 2',
        description: 'Description for project 2',
        link: 'https://example.com/project2'
    }
];

const Projects: React.FC = () => {
    return (
        <div>
            <h1>My Projects</h1>
            {projects.map((project, index) => (
                <ProjectCard key={index} project={project} />
            ))}
        </div>
    );
};

export default Projects;
