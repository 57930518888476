import React from 'react';

const About = () => {
    return (
        <div>
            <h1>About Me</h1>
            <p>This is the about page.</p>
        </div>
    );
};

export default About;
